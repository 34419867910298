import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"


export const Explanation = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <Wrapper id="explanation">
      <SkillsWrapper as={Container}>
        <Details theme={theme}>
          <p>
            QR Codes are a great way to share digital data using phone cameras, but they enable a lot of tracking that we don't yet fully understand! This research project is devoted to finding out how, how much, and how creepily people are being tracked by the computers behind the QR codes that they scan.
          </p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
