import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"



export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>QR Codes are everywhere</h1>
          <h4>Internet style tracking is seeping into the real world.</h4>
          <Button as={AnchorLink} href="#about">
            Help us investigate
          </Button>
        </Details>
        <Thumbnail>
          <StaticImage 
     placeholder="blurred"
     width={500}
     layout="fullWidth"
     imgClassName="art-directed"
     src={'../../../assets/images/qrcodes.jpg'} alt="They're everywhere" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
