import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"



export const Team = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="team">
      <h1>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; Meet the Team</h1>
      <IntroWrapper as={Container}>
        {/*<Details theme={theme}>*/}
          {/*<h2>Team</h2>*/}
        {/*  <h4>Internet style tracking is seeping into the real world.</h4>*/}
        {/*  <Button as={AnchorLink} href="#about">*/}
        {/*    Help us investigate*/}
        {/*  </Button>*/}
        {/*</Details>*/}
        <Thumbnail>
          <StaticImage
     placeholder="blurred"
     width={500}

     layout="fullWidth"
     imgClassName="art-directed"
     src={'../../../assets/images/teamimages/ckanichimage.png'} alt="Chris Picture" />
          <a href={"https://www.cs.uic.edu/~ckanich/"} target={"_blank"}> <h1> &nbsp; &nbsp; Chris Kanich</h1></a>
          <h4>&nbsp; &nbsp; &nbsp; &nbsp; <i>Associate Professor @ UIC</i></h4>
        </Thumbnail>


        <Thumbnail>
          <StaticImage
              placeholder="blurred"
              width={500}

              layout="fullWidth"
              imgClassName="art-directed"
              src={'../../../assets/images/teamimages/lenorezuckimage.png'} alt="Lenore Picture" />
          <a href={"https://cs.uic.edu/profiles/lenore-zuck/"} target={"_blank"}> <h1> &nbsp; &nbsp; &nbsp; Lenore Zuck</h1></a>
          <h4>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<i>Research Professor @ UIC</i></h4>
        </Thumbnail>

        <Thumbnail>
          <StaticImage
              placeholder="blurred"
              width={500}

              layout="fullWidth"
              imgClassName="art-directed"
              src={'../../../assets/images/teamimages/awaisjafarimage.png'} alt="Awais Picture" />
          <a href={"https://www.cs.uic.edu/~awais/"} target={"_blank"}> <h1> &nbsp; &nbsp; &nbsp; Awais Jafar</h1></a>
          <h4>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<i>PhD Student @ UIC</i></h4>
        </Thumbnail>

        <Thumbnail>
          <StaticImage
              placeholder="blurred"
              width={500}
              height={500}
              layout="fullWidth"
              imgClassName="art-directed"
              src={'../../../assets/images/teamimages/rayaansiddiqiimage.png'} alt="Rayaan Picture" />
          <a href={"https://rayaansiddiqi.com"} target={"_blank"}> <h1> &nbsp; &nbsp; Rayaan Siddiqi</h1></a>
          <h4>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <i>Undergraduate @ UIC</i></h4>
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
