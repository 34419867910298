import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"


export const Android = () => {
  const { theme } = useContext(ThemeContext);


  return (
      <Wrapper id="android">
        <SkillsWrapper as={Container}>
          <Thumbnail>
            <StaticImage
                placeholder="blurred"
                layout="fullWidth"
                src={'../../../assets/images/androidqrpic.jpg'} alt="Scan them back" />
          </Thumbnail>
          <Details theme={theme}>
            <h1>📱 Android Installation Instructions</h1>
            <p>
              Tap the set up button to follow instructions on the QR code reporting-process for Android devices. Watch the video provided for additional help.
            </p>

            <Button as="a" target="_blank" href="https://android.qrchecker.app">
              Scan 📥 (Android)
            </Button>
            &nbsp;
            <Button as="a" target="_blank" href="https://youtu.be/8IRopeveYlc">
              View video 📹
            </Button>
          </Details>
        </SkillsWrapper>
      </Wrapper>
  );
};
