import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"


export const About = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
        <StaticImage 
     placeholder="blurred"
     layout="fullWidth"
     width={500}
     imgClassName="art-directed"
     src={'../../../assets/images/peoplescanning.jpeg'} alt="Scan them back" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>🕵️ Help us investigate</h1>
          <p>
            We've created an iOS shortcut and Android method that you can use to snap a pic of a qr code in a matter of seconds! You can also keep track of all the QR codes you've scanned, and get a report on whether we determined if it tries to track people who scan it.
          </p>
          <Button as="a" href="#install">
            iOS Devices 📱
          </Button>
          &nbsp;&nbsp;
          <Button as="a" href="#android">
            Android Devices 📱
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
