import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"


export const Install = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <Wrapper id="install">
      <SkillsWrapper as={Container}>
        <Thumbnail>
        <StaticImage 
     placeholder="blurred"
     layout="fullWidth"
     src={'../../../assets/images/scanner.jpg'} alt="Scan them back" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>📱 iOS Installation Instructions</h1>
          <p>
            Simply install the iOS shortcut. Check out the video explanation of how the shortcut works and why it needs the permissions it does.
          </p>
          <Button as="a" target="_blank" href="/shortcut/latest">
           Install 📥 (iOS)
          </Button>
          &nbsp;
          <Button as="a" target="_blank" href="https://youtu.be/xQOiUlge2Gk">
            View video 📹
          </Button>

        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
