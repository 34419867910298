import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { StaticImage } from "gatsby-plugin-image"


export const Data = () => {
  const { theme } = useContext(ThemeContext);


  return (
    <Wrapper id="data">
      <SkillsWrapper as={Container}>
        <Thumbnail>
        <StaticImage 
     placeholder="blurred"
     layout="fullWidth"
     width={500}
     src={'../../../assets/images/scanner.jpg'} alt="Scan them back" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>Coming Soon: Tracking Reports</h1>
          <p>
            By default, scans that you upload are not linked to you in any way. However, as we improve our privacy analysis, we are building a UI that allows you to link your scan history with your identity and view your scan history and privacy report securely in a web browser.
          </p>
          <Button as={AnchorLink} href="#contact">
            Sign up to receive updates
          </Button>


        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
